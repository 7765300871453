import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { Button, CreditCardInput, ExpiryDateInput, Footer, InputCvv, Input, SelectWithLabel, Header, Notification, SEO } from 'components'
import Patterns from 'patterns'

import cvvImage from 'assets/cvv.png'
import contactImg from 'assets/contact.svg'
import dishIcon from 'assets/dish.svg'
import exclamationIcon from 'assets/exclamation.svg'
import faqImg from 'assets/faq.svg'
import labelIcon from 'assets/label.svg'
import refundImg from 'assets/refund.svg'
import vipClubLogo from 'assets/vipClubLogo.png'

import { actionCreators as billingActionCreators, selectors as billingSelectors } from 'state/BillingPage/reducer'
import { customerAccountBecameVip } from 'state/LoginPage/actions'
import { selectors as loginSelectors } from 'state/LoginPage/reducer'

import * as styles from './VipSignupPage.module.scss'

const Support = ({ img, title, children, marginTop, marginBottom }) => (
  <div className={styles.category} style={{ marginTop, marginBottom }}>
    <img src={img} alt='support' />
    <div className={styles.categoryContainer}>
      <div className={styles.categoryTitle}>{title}</div>
      {children}
    </div>
  </div>
)

const VipSignupPage = ({
  billingState,
  loginState,
  setCardNumber,
  setCvv,
  setExpiryDate,
  setFirstName,
  setLastName,
  setAddress,
  setAddress2,
  setCity,
  setState,
  setZipCode,
  setCountry,
  customerAccountBecameVip
}) => {
  const { landing, shared } = useSiteMetadata()

  const countries = landing.COUNTRIES
  const faqLink = '/faq'
  const contactUsLink = '/support'
  const refundPolicyLink = '/refund-policy'
  const subscriptionPrices = shared.SUBSCRIPTION_PRODUCT

  const paymentApiGateway = {
    authorize: landing.PAYMENT_SETTINGS.apiGateway
  }

  const paymentPluginSettings = {
    type: landing.PAYMENT_PLUGIN,
    apiLoginId: landing.PAYMENT_SETTINGS.apiLoginId,
    clientKey: landing.PAYMENT_SETTINGS.clientKey
  }

  const countriesRegions = {}

  const countriesOptions = Object.values(countries).map(country => {
    countriesRegions[country.id] = country.regions.map(region => ({
      value: region.id,
      label: region.name,
      autocomplete: region.iso2
    }))

    return {
      value: country.id,
      label: country.name,
      autocomplete: country.name
    }
  })

  const [notification, showNotification] = useState()
  const [isShowErrors, setIsShowErrors] = useState(false)
  const [selectedCountryOption, setSelectedCountryOption] = useState(countriesOptions[0])
  const [regionsOptions, setRegionOptions] = useState(countriesRegions[countriesOptions[0].value])

  useEffect(() => {
    window.scrollTo(0, 0)
    trackingGA()
  }, [])

  useEffect(() => {
    showNotification(loginState.UIState.error)
  }, [loginState.UIState.error])

  const countryChangedHandler = selectedCountryOption => {
    setRegionOptions(countriesRegions[selectedCountryOption.value])
    setSelectedCountryOption(selectedCountryOption)
    setCountry(selectedCountryOption)
    setState('')
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsShowErrors(true)
    if (billingState.isValid) {
      const payload = await customerAccountBecameVip({
        landingId: landing.LANDING_ID,
        paymentPluginSettings,
        paymentApiGateway
      })
      if (payload?.payload?.isSaved) {
        navigate('/profile/customer-subscriptions')
      }
    }
  }

  return (
    <div className={styles.vipSignupPage}>
      <SEO />
      <div className={styles.preHeader}>
        <img src={vipClubLogo} alt='VIP CLUB' className={styles.vipClubLogo} />
        <div className={styles.prelogoText}>
          Become a VIP member and shop t-shirts for FREE!{' '}
          <Link to='/membership' className={styles.prelogoTextUnderlined}>
            Details
          </Link>
        </div>
      </div>
      <Header isNeedDescription={false} gap={true} />
      <div className={styles.content}>
        <div className={styles.sectionOne}>
          <div className={styles.sectionOneRightPart}>
            <section className={styles.titlePart}>
              <div className={styles.title}>Welcome to Fan Made Fits VIP Club</div>
              <div className={styles.subtitle}>${(subscriptionPrices.prices[0].memberPrice / 100).toFixed(2)}/Month</div>
              <div className={styles.subtitle}>Cancel Anytime.</div>
              <div className={styles.subtitleRed}>Savings guaranteed. Try it risk free!</div>
              <p className={styles.text}>
                Nice! You are one step away from becoming a VIP member. Fill out your billing address and card information to finish your account.{' '}
                <Link to='/membership' className={styles.textLink}>
                  Learn more
                </Link>{' '}
                about your VIP member benefits and perks!
              </p>
            </section>

            <div className={styles.sectionInfo}>
              <section className={styles.cardInfo}>
                <p className={styles.cardInfoTitle}>Your card information</p>
                <div className={styles.cardNumber}>
                  <CreditCardInput
                    placeholder='XXXX-XXXX-XXXX-XXXX'
                    label='Credit or Debit card number'
                    onChange={setCardNumber}
                    isValid={billingState.validation.cardNumber.isValid}
                    isShowErrors={isShowErrors}
                    errorMessage={billingState.validation.cardNumber.error}
                    defaultValue={billingState.cardNumber}
                  />
                </div>
                <div className={styles.cardInfoRow}>
                  <div className={styles.expiryDate}>
                    <ExpiryDateInput
                      placeholder='MM/YY'
                      label='Expiry Date'
                      pattern={Patterns.expiry}
                      onChange={setExpiryDate}
                      isValid={billingState.validation.expiryDate.isValid}
                      isShowErrors={isShowErrors}
                      errorMessage={billingState.validation.expiryDate.error}
                      defaultValue={billingState.expiryDate}
                    />
                  </div>
                  <div className={styles.cardInfoCvv}>
                    <InputCvv
                      placeholder='***'
                      label='CVV'
                      onChange={setCvv}
                      isValid={billingState.validation.cvv.isValid}
                      isShowErrors={isShowErrors}
                      errorMessage={billingState.validation.cvv.error}
                      defaultValue={billingState.cvv}
                    />
                  </div>
                  <div className={styles.cardInfoImage}>
                    <img src={cvvImage} alt='' />
                    <div className={styles.cardInfoImageText}>CVV is the last 3 digits on the back of your credit card</div>
                  </div>
                </div>
              </section>

              <section className={styles.addressSection}>
                <p className={styles.addressInfoTitle}>Your billing address</p>
                <div className={styles.formItemRow}>
                  <div className={styles.formItemFirstName}>
                    <Input
                      placeholder='First Name'
                      label='First Name'
                      onChange={setFirstName}
                      isValid={billingState.validation.firstName}
                      isShowErrors={isShowErrors}
                      errorMessage='Please input your First Name'
                      defaultValue={billingState.firstName}
                      name='first-name'
                      autoComplete='name first-name'
                    />
                  </div>
                  <div className={styles.formItemLastName}>
                    <Input
                      placeholder='Last Name'
                      label='Last Name'
                      onChange={setLastName}
                      isValid={billingState.validation.lastName}
                      isShowErrors={isShowErrors}
                      errorMessage='Please input your Last Name'
                      defaultValue={billingState.lastName}
                      name='last-name'
                      autoComplete='name last-name'
                    />
                  </div>
                </div>
                <div className={styles.formItemRow}>
                  <div className={styles.formItemAddress}>
                    <Input
                      placeholder='Address (please include Unit/Apt)'
                      label='Address'
                      onChange={setAddress}
                      isValid={billingState.validation.address}
                      isShowErrors={isShowErrors}
                      errorMessage='Please input your Address'
                      defaultValue={billingState.address}
                      name='street-address'
                      autoComplete='shipping street-address'
                      required
                    />
                  </div>
                </div>
                <div className={styles.formItemRow}>
                  <div className={styles.formItemAddress}>
                    <Input
                      placeholder='Address 2'
                      label='Address 2'
                      onChange={setAddress2}
                      isShowErrors={false}
                      errorMessage='Please input your Address 2'
                      defaultValue={billingState.address2}
                    />
                  </div>
                </div>
                <div className={styles.formItemRow}>
                  <div className={styles.formItemCity}>
                    <Input
                      placeholder='City'
                      label='City'
                      onChange={setCity}
                      isValid={billingState.validation.city}
                      isShowErrors={isShowErrors}
                      errorMessage='Please input your City'
                      defaultValue={billingState.city}
                      name='city'
                      autoComplete='shipping address-level2'
                      required
                    />
                  </div>
                </div>
                <div className={styles.formItemSelectCountry}>
                  <SelectWithLabel
                    placeholder='United States of America'
                    label='Country'
                    onSelect={countryChangedHandler}
                    isValid={billingState.validation.country}
                    isShowErrors={isShowErrors}
                    errorMessage='Please select your Country'
                    value={selectedCountryOption}
                    options={countriesOptions}
                    required
                  />
                </div>
                <div className={styles.formItemRow}>
                  <div className={styles.formItemZip}>
                    <Input
                      placeholder='Zip Code'
                      label='Zip Code'
                      onChange={setZipCode}
                      isValid={billingState.validation.zipCode.isValid}
                      isShowErrors={isShowErrors}
                      errorMessage={billingState.validation.zipCode.error}
                      defaultValue={billingState.zipCode}
                      name='postal-code'
                      autoComplete='shipping postal-code'
                      required
                    />
                  </div>
                  <div className={styles.formItemState}>
                    <SelectWithLabel
                      placeholder='Select State'
                      label='State'
                      onSelect={setState}
                      isValid={billingState.validation.state}
                      isShowErrors={isShowErrors}
                      errorMessage='Please select your State'
                      options={regionsOptions}
                      value={billingState.stateOption}
                      inputProps={{
                        name: 'state',
                        autoComplete: 'shipping address-level1'
                      }}
                      required
                    />
                  </div>
                </div>

                <div className={styles.button}>
                  <Button value='Become VIP Now' customAction={handleSubmit} loading={loginState.UIState.isLoading} />
                </div>
                <p className={styles.disclaimer}>
                  *By pressing ‘Become VIP Now’, you agree to start your VIP Club membership. You agree to be charged ${(subscriptionPrices.prices[0].memberPrice / 100).toFixed(2)}{' '}
                  to start your VIP Club membership. A monthly recurring charge will be automatically charged on your card unless canceled. Cancel anytime. Terms apply.
                </p>
              </section>
            </div>

            <section className={styles.sectionMembership}>
              <div className={styles.box}>
                <div className={styles.label}>Save 75%</div>
                <p className={styles.boxPriceTitle}>Membership perks</p>
                <p className={styles.guarantie}>Savings guaranteed. Try it risk free!</p>

                <div className={styles.boxItem}>
                  <img className={styles.labelIcon} src={labelIcon} alt='' />
                  <div className={styles.boxItemDesciption}>
                    <p className={styles.boxItemTitle}>Save Up to 75%</p>
                    <p className={styles.boxItemText}>Get all our products for what we get them for. That means more free shirts, $10 hoodies, $10 sweatshirts, and much more.</p>
                  </div>
                </div>

                <div className={styles.boxItem}>
                  <img className={styles.exclamationIcon} src={exclamationIcon} alt='' />
                  <div className={styles.boxItemDesciption}>
                    <p className={styles.boxItemTitle}>Daily New arrivals</p>
                    <p className={styles.boxItemText}>Enjoy new arrivals everyday for free, every day, never pay full price again.</p>
                  </div>
                </div>

                <div className={styles.boxItem}>
                  <img className={styles.dishIcon} src={dishIcon} alt='' />
                  <div className={styles.boxItemDesciption}>
                    <p className={styles.boxItemTitle}>Concierge Service - No Limits</p>
                    <p className={styles.boxItemText}>
                      Want your own pictures or designs on our products? Want a new product we don’t yet stock? Want special bulk pricing? Just ask your concierge.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className={styles.sectionInfoForDesktop}>
            <div className={styles.subtitleForDesktop}>${(subscriptionPrices.prices[0].memberPrice / 100).toFixed(2)}/Month</div>
            <div className={styles.subtitleForDesktop}>Cancel Anytime.</div>
            <section className={styles.cardInfo}>
              <p className={styles.cardInfoTitle}>Your card information</p>
              <div className={styles.cardNumber}>
                <CreditCardInput
                  placeholder='XXXX-XXXX-XXXX-XXXX'
                  label='Credit or Debit card number'
                  onChange={setCardNumber}
                  isValid={billingState.validation.cardNumber.isValid}
                  isShowErrors={isShowErrors}
                  errorMessage={billingState.validation.cardNumber.error}
                  defaultValue={billingState.cardNumber}
                />
              </div>
              <div className={styles.cardInfoRow}>
                <div className={styles.expiryDate}>
                  <ExpiryDateInput
                    placeholder='MM/YY'
                    label='Expiry Date'
                    pattern={Patterns.expiry}
                    onChange={setExpiryDate}
                    isValid={billingState.validation.expiryDate.isValid}
                    isShowErrors={isShowErrors}
                    errorMessage={billingState.validation.expiryDate.error}
                    defaultValue={billingState.expiryDate}
                  />
                </div>
                <div className={styles.cardInfoCvv}>
                  <InputCvv
                    placeholder='***'
                    label='CVV'
                    onChange={setCvv}
                    isValid={billingState.validation.cvv.isValid}
                    isShowErrors={isShowErrors}
                    errorMessage={billingState.validation.cvv.error}
                    defaultValue={billingState.cvv}
                  />
                </div>
                <div className={styles.cardInfoImage}>
                  <img src={cvvImage} alt='' />
                  <div className={styles.cardInfoImageText}>CVV is the last 3 digits on the back of your credit card</div>
                </div>
              </div>
            </section>

            <section className={styles.addressSection}>
              <p className={styles.addressInfoTitle}>Your billing address</p>
              <div className={styles.formItemRow}>
                <div className={styles.formItemFirstName}>
                  <Input
                    placeholder='First Name'
                    label='First Name'
                    onChange={setFirstName}
                    isValid={billingState.validation.firstName}
                    isShowErrors={isShowErrors}
                    errorMessage='Please input your First Name'
                    defaultValue={billingState.firstName}
                    name='first-name'
                    autoComplete='name first-name'
                  />
                </div>
                <div className={styles.formItemLastName}>
                  <Input
                    placeholder='Last Name'
                    label='Last Name'
                    onChange={setLastName}
                    isValid={billingState.validation.lastName}
                    isShowErrors={isShowErrors}
                    errorMessage='Please input your Last Name'
                    defaultValue={billingState.lastName}
                    name='last-name'
                    autoComplete='name last-name'
                  />
                </div>
              </div>
              <div className={styles.formItemRow}>
                <div className={styles.formItemAddress}>
                  <Input
                    placeholder='Address (please include Unit/Apt)'
                    label='Address'
                    onChange={setAddress}
                    isValid={billingState.validation.address}
                    isShowErrors={isShowErrors}
                    errorMessage='Please input your Address'
                    defaultValue={billingState.address}
                    name='street-address'
                    autoComplete='shipping street-address'
                    required
                  />
                </div>
              </div>
              <div className={styles.formItemRow}>
                <div className={styles.formItemAddress}>
                  <Input
                    placeholder='Address 2'
                    label='Address 2'
                    onChange={setAddress2}
                    isShowErrors={false}
                    errorMessage='Please input your Address 2'
                    defaultValue={billingState.address2}
                  />
                </div>
              </div>
              <div className={styles.formItemRow}>
                <div className={styles.formItemCity}>
                  <Input
                    placeholder='City'
                    label='City'
                    onChange={setCity}
                    isValid={billingState.validation.city}
                    isShowErrors={isShowErrors}
                    errorMessage='Please input your City'
                    defaultValue={billingState.city}
                    name='city'
                    autoComplete='shipping address-level2'
                    required
                  />
                </div>
              </div>
              <div className={styles.formItemSelectCountry}>
                <SelectWithLabel
                  placeholder='United States of America'
                  label='Country'
                  onSelect={countryChangedHandler}
                  isValid={billingState.validation.country}
                  isShowErrors={isShowErrors}
                  errorMessage='Please select your Country'
                  value={selectedCountryOption}
                  options={countriesOptions}
                  required
                />
              </div>
              <div className={styles.formItemRow}>
                <div className={styles.formItemZip}>
                  <Input
                    placeholder='Zip Code'
                    label='Zip Code'
                    onChange={setZipCode}
                    isValid={billingState.validation.zipCode.isValid}
                    isShowErrors={isShowErrors}
                    errorMessage={billingState.validation.zipCode.error}
                    defaultValue={billingState.zipCode}
                    name='postal-code'
                    autoComplete='shipping postal-code'
                    required
                  />
                </div>
                <div className={styles.formItemState}>
                  <SelectWithLabel
                    placeholder='Select State'
                    label='State'
                    onSelect={setState}
                    isValid={billingState.validation.state}
                    isShowErrors={isShowErrors}
                    errorMessage='Please select your State'
                    options={regionsOptions}
                    value={billingState.stateOption}
                    inputProps={{
                      name: 'state',
                      autoComplete: 'shipping address-level1'
                    }}
                    required
                  />
                </div>
              </div>

              <div className={styles.button}>
                <Button value='Become VIP Now' customAction={handleSubmit} loading={loginState.UIState.isLoading} />
              </div>
              <p className={styles.disclaimer}>
                *By pressing ‘Become VIP Now’, you agree to start your VIP Club membership. You agree to be charged ${(subscriptionPrices.prices[0].memberPrice / 100).toFixed(2)}{' '}
                to start your VIP Club membership. A monthly recurring charge will be automatically charged on your card unless canceled. Cancel anytime. Terms apply.
              </p>
            </section>
          </div>
        </div>

        <div className={styles.sectionTwo}>
          <section className={styles.sectionGrayOne}>
            <div className={styles.mainBlock}>
              <div className={styles.mainBlockTitle}>Why Give Away Free Shirts?</div>
              <div className={styles.mainBlockDescription}>
                “I’m tired of seeing logos on clothing. I want to see more people showing off what they’re passionate about. I’m starting a revolution. By giving a few people free
                passionate shirts, I know more will follow. Let’s make this happen.”
              </div>
              <div className={styles.mainBlockFooter}>-Jason, Founder of Fan Made Fits</div>
            </div>
          </section>

          <section className={styles.sectionGrayTwo}>
            <div className={styles.titleNeedHelp}>Need Help?</div>

            <Support img={faqImg} title='Have questions about our shop?' marginTop={30} marginBottom={20}>
              <a href={faqLink} target='_blank' rel='noopener noreferrer' className={styles.link}>
                Click here to get answers from FAQ’s
              </a>
            </Support>

            <Support img={contactImg} title='Need to reach out to us?' marginBottom={20}>
              <a href={contactUsLink} rel='noopener noreferrer' target='_blank' className={styles.link}>
                Click here to contact us
              </a>
            </Support>

            <Support img={refundImg} title='Not completely satisfied?' marginBottom={40}>
              <a href={refundPolicyLink} rel='noopener noreferrer' target='_blank' className={styles.link}>
                Click here to see refund policy
              </a>
            </Support>
          </section>
        </div>
        <div className={styles.buttonToTop}>
          <Button value='Back to Top' customAction={e => window.scroll({ top: 0, behavior: 'smooth' })} />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer />
        <Notification message={notification} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  billingState: billingSelectors.selectBillingState(state),
  loginState: loginSelectors.selectLoginState(state)
})

const mapDispatchToProps = {
  setCardNumber: billingActionCreators.setCardNumber,
  setExpiryDate: billingActionCreators.setExpiryDate,
  setCvv: billingActionCreators.setCvv,
  setFirstName: billingActionCreators.setFirstName,
  setLastName: billingActionCreators.setLastName,
  setAddress: billingActionCreators.setAddress,
  setAddress2: billingActionCreators.setAddress2,
  setCity: billingActionCreators.setCity,
  setCountry: billingActionCreators.setCountry,
  setZipCode: billingActionCreators.setZipCode,
  setState: billingActionCreators.setState,
  customerAccountBecameVip: customerAccountBecameVip
}

export default connect(mapStateToProps, mapDispatchToProps)(VipSignupPage)
