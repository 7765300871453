// extracted by mini-css-extract-plugin
export var vipSignupPage = "VipSignupPage-module--vipSignupPage--a7KZG";
export var preHeader = "VipSignupPage-module--preHeader--8XTEk";
export var vipClubLogo = "VipSignupPage-module--vipClubLogo--1-lGL";
export var formItemFirstName = "VipSignupPage-module--formItemFirstName--3iVVP";
export var formItemLastName = "VipSignupPage-module--formItemLastName--1k4hp";
export var prelogoText = "VipSignupPage-module--prelogoText--DeZEd";
export var prelogoTextUnderlined = "VipSignupPage-module--prelogoTextUnderlined--iFYpi";
export var content = "VipSignupPage-module--content--1drLJ";
export var sectionOne = "VipSignupPage-module--sectionOne--LjpDi";
export var sectionOneRightPart = "VipSignupPage-module--sectionOneRightPart--WB756";
export var sectionTwo = "VipSignupPage-module--sectionTwo--1DycL";
export var sectionInfo = "VipSignupPage-module--sectionInfo--HK3ij";
export var sectionInfoForDesktop = "VipSignupPage-module--sectionInfoForDesktop--QBxRs";
export var titlePart = "VipSignupPage-module--titlePart--QEvnu";
export var title = "VipSignupPage-module--title--3BHL3";
export var subtitle = "VipSignupPage-module--subtitle--1lsBs";
export var subtitleForDesktop = "VipSignupPage-module--subtitleForDesktop--3pdXg";
export var subtitleRed = "VipSignupPage-module--subtitleRed--3X7VE";
export var text = "VipSignupPage-module--text--Iolcs";
export var textLink = "VipSignupPage-module--textLink--D4t0w";
export var cardInfo = "VipSignupPage-module--cardInfo--2i1oW";
export var cardInfoTitle = "VipSignupPage-module--cardInfoTitle--1VRVH";
export var cardNumber = "VipSignupPage-module--cardNumber--YSkCx";
export var cardInfoRow = "VipSignupPage-module--cardInfoRow--2g2er";
export var expiryDate = "VipSignupPage-module--expiryDate--15Sjd";
export var cardInfoCvv = "VipSignupPage-module--cardInfoCvv--2NMpR";
export var cardInfoImage = "VipSignupPage-module--cardInfoImage--JINcE";
export var cardInfoImageText = "VipSignupPage-module--cardInfoImageText--2PWUk";
export var addressSection = "VipSignupPage-module--addressSection--18keu";
export var addressInfoTitle = "VipSignupPage-module--addressInfoTitle--2dfXv";
export var formItemRow = "VipSignupPage-module--formItemRow--3Pk4t";
export var formItemAddress = "VipSignupPage-module--formItemAddress--1SzIC";
export var formItemCity = "VipSignupPage-module--formItemCity--5DUX3";
export var formItemSelectCountry = "VipSignupPage-module--formItemSelectCountry--EpMaq";
export var formItemZip = "VipSignupPage-module--formItemZip--1QDeB";
export var formItemState = "VipSignupPage-module--formItemState--Ttrsg";
export var button = "VipSignupPage-module--button--2LLkw";
export var buttonToTop = "VipSignupPage-module--buttonToTop--1jAvD";
export var disclaimer = "VipSignupPage-module--disclaimer--kaU_T";
export var sectionMembership = "VipSignupPage-module--sectionMembership--Lw8mm";
export var box = "VipSignupPage-module--box--3SCHI";
export var label = "VipSignupPage-module--label--3hjt0";
export var boxPriceTitle = "VipSignupPage-module--boxPriceTitle--1x_wx";
export var guarantie = "VipSignupPage-module--guarantie--1GBym";
export var boxItem = "VipSignupPage-module--boxItem--1Xs2l";
export var boxItemDesciption = "VipSignupPage-module--boxItemDesciption--Ma6je";
export var labelIcon = "VipSignupPage-module--labelIcon--M7tvG";
export var exclamationIcon = "VipSignupPage-module--exclamationIcon--3y1DJ";
export var dishIcon = "VipSignupPage-module--dishIcon--2hUzL";
export var boxItemTitle = "VipSignupPage-module--boxItemTitle--3ja9n";
export var boxItemText = "VipSignupPage-module--boxItemText--2IF06";
export var sectionGrayOne = "VipSignupPage-module--sectionGrayOne--2uM-G";
export var sectionGrayTwo = "VipSignupPage-module--sectionGrayTwo--3S9eT";
export var mainBlock = "VipSignupPage-module--mainBlock--3Rklg";
export var mainBlockTitle = "VipSignupPage-module--mainBlockTitle--6YhiR";
export var mainBlockDescription = "VipSignupPage-module--mainBlockDescription--1Kb86";
export var mainBlockFooter = "VipSignupPage-module--mainBlockFooter--1_pUA";
export var titleNeedHelp = "VipSignupPage-module--titleNeedHelp--27T67";
export var category = "VipSignupPage-module--category--WJJ42";
export var categoryContainer = "VipSignupPage-module--categoryContainer--2VZOe";
export var categoryTitle = "VipSignupPage-module--categoryTitle--GHBfd";
export var link = "VipSignupPage-module--link--5V3tY";
export var footerContainer = "VipSignupPage-module--footerContainer--1cxWO";